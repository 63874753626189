


































































































.setting-row {
  .title-container {
    line-height: 20px;
  }
  .boolean-container {
    margin-top: 5px;
    .readonly-check {
      .v-input--selection-controls {
        margin-top: 0;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
      .v-messages {
        display: none;
      }
    }
  }
  .string-container {
    overflow: hidden;
    code {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
